@media only screen and (max-width: 375px) {
	html,
	body {
		font-size: 70%;
	}
}

/* up to small mobile device */
@media only screen and (max-width: 320px) {
	html,
	body {
		font-size: 65%;
	}

	.slanted-box-container {
		height: 150px;
	}

	.raise-md {
		margin-top: -90px;
	}

	.raise-sm {
		margin-top: -115px;
	}

	.name-container {
		margin-top: -2rem;
		margin-bottom: 2rem;
	}
}

/* small to medium mobile device */
@media only screen and (min-width: 320px) {
	.slanted-box-container {
		height: 150px;
	}

	.raise-md {
		margin-top: -90px;
	}

	.raise-sm {
		margin-top: -115px;
	}

	.name-container {
		margin-top: -0.25rem;
		margin-bottom: 3rem;
	}
}

/* medium to large mobile device */
@media only screen and (min-width: 375px) {
	html,
	body {
		font-size: 75%;
	}

	.slanted-box-container {
		height: 150px;
	}

	.raise-md {
		margin-top: -90px;
	}

	.raise-sm {
		margin-top: -115px;
	}
}

@media only screen and (max-width: 425px) {
	.contact-logo {
		height: 16px;
	}
}

/* large mobile device to tablet */
@media only screen and (min-width: 425px) {
	html,
	body {
		font-size: 80%;
	}

	.slanted-box-container {
		height: 200px;
	}

	.raise-md {
		margin-top: -140px;
	}

	.raise-sm {
		margin-top: -165px;
	}

	.name-container {
		margin-bottom: 4rem;
	}

	.contact-container {
		justify-content: center;
	}
}

@media only screen and (min-width: 540px) and (max-width: 575px) {
	.project-screenshot {
		width: 80%;
		border-radius: 5px;
	}
}

@media only screen and (max-width: 640px) {
	.section-image-container {
		display: none;
	}

	.section-item-info-container {
		margin-right: 2rem;
	}

	.contact-logo {
		height: 24px;
	}
}

@media only screen and (max-width: 768px) {
	.section-item-image {
		transform: scale(0.3);
	}

	.skills-item-image {
		transform: scale(0.2);
	}

	.skills-image {
		width: 3rem;
	}
}

@media only screen and (min-width: 768px) and (max-width: 900px) {
	.skills-item-image {
		transform: scale(0.25);
	}

	.skills-image {
		width: 3rem;
	}
}

/* tablet to laptop */
@media only screen and (min-width: 768px) {
	html,
	body {
		font-size: 90%;
	}

	.slanted-box-container {
		height: 250px;
	}

	.raise-md {
		margin-top: -190px;
	}

	.raise-sm {
		margin-top: -215px;
	}

	.name-container {
		margin-bottom: 4rem;
	}

	.footer {
		flex-direction: row;
		font-size: 1rem;
	}

	.footer-built-with {
		display: flex;
	}

	.contact-container {
		margin-bottom: 0;
	}
}

@media only screen and (min-width: 900px) {
	.footer {
		font-size: 1.2rem;
	}
}

@media only screen and (min-width: 900px) and (max-width: 1024px) {
	.skills-item-image {
		transform: scale(0.32);
	}
}

/* tablet to large laptop  */
@media only screen and (min-width: 1024px) {
	html,
	body {
		font-size: 100%;
	}

	.slanted-box-container {
		height: 350px;
	}

	.raise-md {
		margin-top: -290px;
	}

	.raise-sm {
		margin-top: -315px;
		height: 300px;
	}

	.name-container {
		margin-top: -5rem;
	}
}

@media only screen and (min-width: 1024px) and (max-width: 1250px) {
	.skills-item-image {
		transform: scale(0.37);
	}
}

/* from large laptop to 4K */
@media only screen and (min-width: 1440px) {
	.slanted-box-container {
		height: 550px;
	}

	.raise-md {
		margin-top: -490px;
	}

	.raise-sm {
		margin-top: -515px;
		height: 425px;
	}

	.name-container {
		margin-top: -14rem;
	}
}

@media only screen and (min-width: 1800px) {
	.name-container {
		margin-top: -9rem;
	}

	.modal-body > img {
		width: 80%;
		border-radius: 5px;
	}
}

@media only screen and (min-width: 2200px) {
	.name-container {
		margin-top: -5rem;
	}

	.raise-sm {
		height: 600px;
	}
}

/* 4K+ */
@media only screen and (min-width: 2560px) {
	html,
	body {
		font-size: 120%;
	}

	.slanted-box-container {
		height: 950px;
	}

	.raise-md {
		margin-top: -890px;
	}

	.raise-sm {
		margin-top: -915px;
		height: 650px;
	}

	.name-container {
		margin-top: -20rem;
	}
}

/* from before */

@media only screen and (max-width: 750px) {
	.nav-list {
		display: none;
		background-color: var(--foreground);
	}

	.section-container {
		margin-left: 2rem;
		margin-right: 2rem;
	}

	.menu-icon {
		display: block;
		height: inherit;
		width: 2em;
		margin-right: 1em;
		cursor: pointer;
		fill: #d33e43;
	}

	.nav-item {
		padding-top: 0.5em;
		padding-bottom: 0.5em;
		margin-left: 0;
		width: 100%;
		background-color: var(--foreground);
		color: var(--background);
		border-bottom: 1px solid var(--background);
	}

	.show-nav {
		display: flex;
		margin-top: 4em;
		flex-direction: column;
		justify-content: flex-start;
		width: inherit;
		position: absolute;
	}

	.navbar-small .show-nav {
		margin-top: 2.75em;
	}

	.nav-item:hover {
		background-color: #e0787b;
		color: var(--foreground);
		text-decoration: none;
	}

	.expand-center,
	.expand-center:hover {
		background: none;
		color: inherit;
	}
}
