.skills-name {
	font-size: 3rem;
	font-weight: bold;
	margin-bottom: 1rem;
}

.skills-images-container {
	margin-top: 1rem;
}

.skills-image {
	width: 4rem;
	height: inherit;
	margin-left: 1rem;
	margin-right: 1rem;
}

.skills-item-image {
	overflow: visible;
	transform: scale(0.4);
}

.cloud .list-item::before {
	content: "☁️ ";
	margin-inline-start: -3.3ch;
}
