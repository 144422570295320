.section-container {
	margin-top: -3.75rem;
	padding-top: 3.75rem;
	margin-bottom: 3.75rem;
	margin-left: 4rem;
	margin-right: 4rem;
	overflow: hidden;
}

.section-title {
	display: inline-block;

	font-size: 3rem;
	text-shadow: 2px 2px #d33e43;
}

.section-item {
	display: flex;
	width: 100%;
	height: 30rem;

	justify-content: center;

	margin-top: 2rem;
	margin-bottom: 2rem;
	border-radius: 0.5rem;

	background-color: var(--darker);

	flex-grow: 1;
	flex-shrink: 1;
	flex-basis: 0;
}

.section-item-header {
	text-align: center;
}

/* SectionItemList */

.section-item-list {
	margin: 0;
	padding: 0;
	margin-left: 2rem;
	margin-top: 2rem;

	font-size: 1.2rem;
}

/* SectionItemInfo */

.section-item-info-container {
	display: flex;
	justify-content: center;
	align-items: center;

	width: 100%;
	height: 100%;

	margin-left: 2rem;
	margin-right: 1rem;
}

.section-item-info {
	height: 100%;
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.section-image-container {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 100%;
	min-width: 0;
	margin-left: 1rem;
	margin-right: 2rem;
}

.section-item-image {
	transform: scale(0.5);
	overflow: visible;

	position: absolute;
}

.list-item {
	margin: 0;
	padding: 0;
}

.list-item {
	list-style-type: none;
}
