.header {
	display: block;
	font-size: 16px;
	position: sticky;
	top: 0;
	z-index: 1;
}

/* the navbar itself */
.navbar {
	display: flex;
	/* justify-content: flex-end; */
	position: sticky;
	z-index: 1;

	width: 100%;
	height: 4em;
	top: 0;
	transition: height 0.3s linear;
	font-size: 16px;

	box-shadow: 0 4px 2px -2px var(--shadow);
	background-color: var(--background);
}

/* list of nav-items */
.nav-list {
	display: flex;
	justify-content: flex-end;

	width: 100%;

	height: inherit;
}

/* nav item */
.nav-item {
	display: flex;
	justify-content: center;
	align-items: center;

	text-decoration: none;

	width: 8em;
	padding-left: 0.5em;
	padding-right: 0.5em;
	color: inherit;

	cursor: pointer;
}

.nav-brand {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	width: 100%;
	height: inherit;
	font-weight: 700;
	margin-left: 1em;
}

.nav-brand .first-letter {
	height: inherit;
	display: inline-block;
	font-size: 2em;
	text-shadow: none;
}

.nav-brand .other-letters {
	height: inherit;
	display: inline-block;
	font-size: 2em;
}

.navbar-small {
	height: 2.75em;
	transition: height 0.3s linear;
}

.expand-center {
	background-image: linear-gradient(#e0787b, #e0787b);
	padding-bottom: 2px;
	background-size: 0 1px;
	background-repeat: no-repeat;
	background-position: center bottom;
	transition: all 0.2s ease-out;
}

.expand-center:hover {
	background-size: 100% 1px;
	color: #e0787b;
}

.active {
	color: #d33e43;
}
