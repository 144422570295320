:root[color-mode="dark"] {
	--background: #2d2c2b;
	/* --foreground: #ccd3d9; */
	/* --foreground: #b8bec3; */
	--foreground: #d4d6da;
	--darker: #201f1f;
	--darker-inverse: #abafb6;
	--shadow: #3a3937;
	--scroll-thumb: #4e4d4a;
	--scroll-track: var(--darker);
	--invert-percent: 0%;
	--modal: #1a1919;
}

:root[color-mode="light"] {
	/* --background: #ccd3d9; */
	/* --background: #b8bec3; */
	--background: #d4d6da;
	--foreground: #2d2c2b;
	--darker: #abafb6;
	--darker-inverse: #201f1f;
	--shadow: #a7a7a7;
	--scroll-thumb: #676767;
	--scroll-track: var(--shadow);
	--invert-percent: 100%;
	--modal: #90959e;
}

@media (prefers-color-scheme: dark) {
	:root {
		--background: #2d2c2b;
		/* --foreground: #ccd3d9; */
		/* --foreground: #b8bec3; */
		--foreground: #d4d6da;
		--darker: #201f1f;
		--darker-inverse: #abafb6;
		--shadow: #3a3937;
		--scroll-thumb: #4e4d4a;
		--scroll-track: var(--darker);
	}
}

@media (prefers-color-scheme: light) {
	:root {
		--background: #d4d6da;
		--foreground: #2d2c2b;
		--darker: #abafb6;
		--darker-inverse: #201f1f;
		--shadow: #a7a7a7;
		--scroll-thumb: #676767;
		--scroll-track: var(--shadow);
	}
}

* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

html {
	scroll-behavior: smooth;
}

html,
body {
	font-size: 100%;
	font-weight: 400;
	font-family: "IBM Plex Mono", monospace;
	scroll-behavior: smooth;
	color: #ccd3d9;
	color: var(--foreground);
	height: 100%;
}

body {
	/* min-height: 100vh; */
	/* margin-left: 2rem;
	margin-right: 2rem; */
	/* -webkit-text-size-adjust: 100%; */
	background-color: #ccd3d9;
	background-color: #565554;
	background-color: #41403f;
	background-color: #363534;
	background-color: #2d2c2b;
	background-color: var(--background);
}

#root {
	min-height: 100vh;
	position: relative;
	/* height: 4000px; */
	/* width: 100%; */
}

.content {
	/* height: 4000px; */
	padding-bottom: 5.5rem;
}

/* name */

.sticky-logo {
	position: sticky;
	top: 0;
	left: 0;
	text-align: start;

	display: flex;
	align-items: center;
	height: 4rem;
	margin-left: 1rem;
	width: 50%;
}

.menu-icon {
	display: none;
}

.section-subtitle {
	/* position: absolute; */
	font-size: 2.5rem;
	/* color: var(--darker-inverse); */
}

/* project */

.project-container {
	height: 22.5rem;
	width: 40rem;
	opacity: 0.3;
	border: 1px solid black;
}

.project-container:hover {
	opacity: 1;
}

.projects-container {
	display: flex;
}

/* Wobble Vertical */
@-webkit-keyframes wobble {
	16.65% {
		-webkit-transform: translateY(8px);
		transform: translateY(8px);
	}
	33.3% {
		-webkit-transform: translateY(-6px);
		transform: translateY(-6px);
	}
	49.95% {
		-webkit-transform: translateY(4px);
		transform: translateY(4px);
	}
	66.6% {
		-webkit-transform: translateY(-2px);
		transform: translateY(-2px);
	}
	83.25% {
		-webkit-transform: translateY(1px);
		transform: translateY(1px);
	}
	100% {
		-webkit-transform: translateY(0);
		transform: translateY(0);
	}
}
@keyframes wobble {
	16.65% {
		-webkit-transform: translateY(8px);
		transform: translateY(8px);
	}
	33.3% {
		-webkit-transform: translateY(-6px);
		transform: translateY(-6px);
	}
	49.95% {
		-webkit-transform: translateY(4px);
		transform: translateY(4px);
	}
	66.6% {
		-webkit-transform: translateY(-2px);
		transform: translateY(-2px);
	}
	83.25% {
		-webkit-transform: translateY(1px);
		transform: translateY(1px);
	}
	100% {
		-webkit-transform: translateY(0);
		transform: translateY(0);
	}
}

.wobble {
	display: inline-block;
}
.wobble:hover,
.wobble:focus,
.wobble:active {
	-webkit-animation-name: wobble;
	animation-name: wobble;
	-webkit-animation-duration: 1s;
	animation-duration: 1s;
	-webkit-animation-timing-function: ease-in-out;
	animation-timing-function: ease-in-out;
	-webkit-animation-iteration-count: 1;
	animation-iteration-count: 1;
}

/* scroll bar */

::-webkit-scrollbar {
	width: 10px;
}

::-webkit-scrollbar-track {
	background-color: var(--scroll-track);
}

::-webkit-scrollbar-thumb {
	/* background-color: #d33e43; */
	background-color: var(--scroll-thumb);
	border-radius: 30px;
}

::-webkit-scrollbar-thumb:hover {
	background-color: #e0787b;
}

.transparent {
	background-color: #c5d4e8;
}

/* footer */

.footer {
	/* display: grid;
	grid-template-columns: 1fr;
	justify-items: center; */
	position: absolute;
	bottom: 0;
	width: 100%;
	height: 4rem;
	font-size: 1.2rem;
	/* row-gap: 1rem; */
}

.footer {
	display: flex;
	justify-content: space-evenly;
	align-items: center;
	/* flex-direction: column; */
	flex-direction: column-reverse;
	margin-bottom: 0.5rem;
	margin-top: 1rem;
}

.footer-built-with {
	display: none;
	width: 100%;
	justify-content: center;
	align-items: center;
	/* margin-left: 10rem; */
	/* opacity: 0; */
}

.footer-made-with {
	display: flex;
	width: 100%;
	justify-content: center;
	align-items: center;
}

.built-with-icon {
	height: 28px;
	margin-left: 1.5rem;
}

.contact-container {
	display: flex;
	width: 100%;
	/* justify-content: center; */
	align-items: center;
	margin-bottom: 1rem;
	justify-content: space-evenly;
}

.contact-logo {
	height: 28px;
	margin-left: 1.5rem;
}

.contact-bw {
	filter: invert(var(--invert-percent));
}
