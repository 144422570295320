.modal-container {
	display: flex;
	justify-content: center;
	align-items: center;
	position: fixed;
	top: 0;
	left: 0;

	/* background-color: var(--); */

	/* filter: blur(1px); */

	height: 100vh;
	width: 100vw;
	z-index: 1;
}

.modal {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;

	height: 90%;
	width: 80%;

	background-color: var(--modal);
	border-radius: 10px;
}

.modal-body > img {
	width: 90%;
	border-radius: 5px;
}

.modal-body {
	display: flex;
	justify-content: center;
	align-items: center;

	/* height: 90%;
	width: 80%; */
}

.modal-header {
	width: 90%;
	text-align: right;
	/* margin-bottom: 2rem; */
}

.btn {
	all: unset;
	cursor: pointer;
	font-size: 1.25rem;
	color: #d33e43;
	border-radius: 5px;
}

.modal-footer {
	width: 90%;
	margin-top: 3.5rem;
	text-align: center;
}

button:focus {
	outline: #d33e43 5px auto;
}

.btn-outline {
	border: 1px solid #d33e43;
}

.btn-md {
	padding-left: 1rem;
	padding-right: 1rem;
	font-size: 1.6rem;
}

.btn-outline:hover {
	background-color: #d33e43;
	color: var(--modal);
}

@media screen and (orientation: portrait) {
	.modal {
		height: 50%;
	}
}

.btn-outline-primary {
	border: 1px solid var(--foreground);
	color: var(--foreground);
}

.btn-outline-primary:hover {
	background-color: var(--foreground);
	color: var(--background);
}

.btn-sm {
	padding-left: 0.5rem;
	padding-right: 0.5rem;
	font-size: 1rem;
}

.btn-container {
	display: flex;
	justify-content: space-around;
	margin-top: 1rem;
	width: 90%;
}
