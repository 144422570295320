.projects {
	height: 34rem;
	/* min-height: 32rem; */
}

.projects-list {
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(20rem, 1fr));
	column-gap: 1rem;
}

.project-section-item {
	display: flex;
	flex-direction: column;
	align-items: center;
	/* justify-content: center; */
	height: inherit;
	width: 100%;
	margin-top: 1rem;
}

.project-section-item > .skills-images-container {
	text-align: center;
}

.project-skills {
	width: 2.1rem !important;
	margin-left: 0.5rem !important;
	margin-right: 0.5rem !important;
}

.project-ss-container {
	display: flex;
	align-items: center;
	justify-content: center;
}

.project-screenshot {
	margin-top: 1rem;
	width: 90%;
	cursor: pointer;
}

.project-info {
	margin-top: 1rem;
	font-size: 1rem;
	width: 90%;

	display: flex;
	align-items: center;
	margin-left: 2rem;
}

.project-info-list {
	width: 100%;
}

.project-section-item .section-item-header {
	font-size: 2rem;
}

.btn > a {
	all: unset;
}

.btn > a:visited {
	color: inherit;
}
